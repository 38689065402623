function beforeMidnight() {
    let mid = new Date(),
        ts = mid.getTime();
    mid.setHours(24, 0, 0, 0);
    return Math.floor((mid - ts) / 60000);
}

function setCookie(cname, cvalue) {
    'use strict';
    let d = new Date();
    d.setTime(d.getTime() + (beforeMidnight() * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

function getCookie(cname) {
    'use strict';
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

let displayPopupAd = function (elem) {
    let duration = parseInt($(elem).data('duration'), 10);

    if ($(elem).hasClass('modal')) {
        setInterval(function () {
            duration--;
            $('.ads-timer').text(duration + ' seg');
            if (duration <= 0) {
                $(elem).modal('hide');
                $('body').removeClass('overflow-hidden');
                clearInterval(this)
            }
        }, 1000);
        $(elem).modal('show');
    } else {
        setInterval(function () {
            duration--;
            $('.ads-timer').text(duration + ' seg');
            if (duration <= 0) {
                $(elem).addClass('hidden');
                clearInterval(this)
            }
        }, 1000);
        $(elem).removeClass('hidden');
        $('body').removeClass('overflow-hidden');
    }

    // save that user has close the popup
    setCookie('userClosedPopup', true);
};

$(window).on('load', function () {
    if ($('.js-ad-elem').length > 0) {
        if (getCookie('userClosedPopup') !== 'true') {
            displayPopupAd('.js-ad-elem');
        } else {
            $('#popup-ad').remove();
        }
    }

    const isMobile = window.orientation > -1;

    if (isMobile) {
      $(".mobile-widget").each(function (index, widget) {
        const items = $(widget).find(".widget-item");
        items.slice(5).addClass("hidden");
      });
    }
});
